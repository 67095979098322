import {publishEvent} from './services/analytics/pub-sub';
import {ANALYTIC_EVENTS} from './services/analytics/pub-sub/constants';
import {trackShares} from './services/track-shares';
import {handleGrowthBookUpdateEvent} from './website-ui/helpers/growthbook';

window.addEventListener('DOMContentLoaded', () => {
  trackShares();
  publishEvent(ANALYTIC_EVENTS.PAGEVIEW);
});

handleGrowthBookUpdateEvent(growthBook => {
  const features = growthBook.getFeatures();

  const featureNames = Object.keys(features);
  const variations = [];
  const experimentIds = [];
  const variationIds = [];

  featureNames.forEach(featureName => {
    const feature = growthBook.evalFeature(featureName);

    variations.push(feature.value);

    if (feature.experiment) {
      experimentIds.push(feature.experiment.key);
      variationIds.push(feature.experimentResult.key);
    }
  });

  publishEvent(ANALYTIC_EVENTS.GROWTHBOOK_INIT, {
    feature_names: featureNames,
    feature_values: variations,
    experiment_ids: experimentIds,
    variation_ids: variationIds
  });
});

